* {
    box-sizing: border-box;
  }
  
  body {
    margin: 0;
    background: url(../static/img/bg.png);
    background-size: cover;
    font-family: josefin sans;
  }
  
  .header {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
  }
  
  .header-title {
    width: 350px;
    height: 104px;
    border-radius: 0px 0px 200px 200px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  section {
    overflow-x: hidden;
    padding: 0 16px;
  }
  
  .bg-item1__img {
    position: absolute;
    width: 216px;
    height: 80px;
    left: 63px;
    z-index: 999;
  }
  
  .bg-item1__shadow,
  .bg-item2__shadow {
    position: absolute;
    width: 216px;
    height: 80px;
    left: 63px;
    background: #ffffff;
    opacity: 0.3;
    filter: blur(47px);
    border-radius: 100px;
    transform: rotate(-60deg);
  }
  
  .bg-item2 {
    position: absolute;
    right: 212px;
  }
  
  .bg-item2__img {
    position: absolute;
    height: 80px;
    left: 63px;
    z-index: 999;
  }
  
  .bg-item2__shadow {
    transform: rotate(-30deg);
    top: -12px;
    left: -5px;
  }

  
  .content {
    position: relative;
    width: 100%;
    height: 100%;
    margin-top: 42px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #022D0D;
  }
  
  .content-title {
    font-size: 64px;
    font-weight: 600;
    margin: 0px 0px 32px 0px;
    text-align: center;
  }
  
  .content-text1 {
    font-size: 32px;
    font-weight: 300;
    margin: 0px;
  }
  
  .content-text2 {
    position: relative;
    width: 306px;
    height: 49px;
    margin: 24px 0px 16px 0px;
    font-size: 24px;
    font-weight: 300;
  }
  
  .content-text2__end {
    position: absolute;
    top: 21px;
    right: -16px;
    background-color: #111111;
    color: white;
    mix-blend-mode: multiply;
    padding: 4px 1px 0px 1px;
  }
  
  .contact-btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .contact-btn {
    all: unset;
    display: flex;
    align-items: center;
    width: 264px;
    height: 80px;
    background: #7dbc74;
    border-radius: 100px;
    font-size: 24px;
    color: #ffffff;
    box-shadow: grey 0px 30px 41px -30px;
    cursor: pointer;
  }
  
  .contact-btn__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 56px;
    margin: 0 23px 0 12px;
  
    background: #ffffff;
    border-radius: 100px;
  }
  
  #mc-embedded-subscribe-form {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 150px 0 76px 0;
  }
  
  .form {
    width: 584px;
    background: #7dbc74;
    border-radius: 16px;
    padding: 48px;
    color: #f2f4f8;
  }
  
  .form__title {
    margin: 0px 0px 32px 0px;
    font-size: 48px;
    font-weight: 600;
  }
  
  .form__inputs-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 32px;
  }
  
  .form__inputs-item__title {
    font-size: 26px;
    margin-bottom: 16px;
  }
  
  .form__inputs-item__label {
    background: rgba(255, 255, 255, 0.9);
    border-radius: 10px;
  
    width: 100%;
    height: 72px;
  }
  
  .form__inputs-item__label-img {
    margin: 0px 16px 0px 24px;
    vertical-align: middle;
  }
  
  .form__inputs-item__label-input {
    width: calc(100% - 85px);
    height: 100%;
    font-size: 20px;
    background: transparent;
    border: none;
    outline: none;
    color: #022D0D;
  
  }
  
  .form__inputs-item__label-input::placeholder {
  color: #A2A9B0;
  }
  
  .form__inputs-item:nth-child(4) {
    margin-bottom: 56px;
  }
  
  .form__btn {
    all: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 84px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 10px;
    font-size: 28px;
    color: #7dbc74;
    cursor: pointer;
  }