

@media screen and (max-width: 768px)  {
  section {
    padding: 0 16px;
  }

  .animation-svg {
    height: 650px;
  }

  .bg-item1__img {
    height: 43px;
    left: -67px;
  }

  .bg-item1__shadow{
    height: 80px;
    left: -66px;
    top: 87px;
  }

  .bg-item2__img {
      top: 10px;
    left: 132px;
  }

  .bg-item2__img > img {
    width: 60px;
  }

  .bg-item2__shadow {
    width: 173px;
    height: 59px;
    top: -5px;
    left: 79px;
  }

  .content-title {
    font-size: 32px;
  }

  .content-text1 {
    font-size: 24px;
    text-align: center;
  }

  .content-text2 {
    font-size: 16px;
    text-align: center;
  }
  .content-text2__end {
    top: 14px;
    right: 15px;
  }

  .contact-btn {
    height: 56px;
    font-size: 20px;
  }

  .contact-btn__icon{
    width: 40px;
    height: 40px;
    margin-right: 32px;
  }

  #form {
    margin: 48px 0 24px 0;
  }

  .form__title{
    font-size: 32px;
  }

  .form__inputs-item{
      margin-bottom: 16px;
  }

  .form__inputs-item__label{
      height: 48px;
  }

  .form__inputs-item__title{
    font-size: 20px;
    margin-bottom: 8px;
  }

}

@media screen and (max-width: 480px) {
    .animation-svg {
        height: 500px;
      }
}
