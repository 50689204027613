.animation {
  display: flex;
  justify-content: center;
  margin-top: -200px;
}


/* .lines {
  transform: translate(-200px, 65px);
  animation: lines 0.7s linear infinite;
} */


@keyframes lines {
  0% {
    transform: translate(-200px, 65px);
  }

  100% {
    transform: translate(140px, -53px);
  }
}

